<template>
  <div id="ajustes" class="ajustes">
    <div class="layout align-center layout px-4 pt-4 app--page-header row">
      <ul class="v-breadcrumbs" style="justify-content: flex-start;">
        <li style="margin-right: -10px">
          <v-btn class="breadcrumb_item" text icon to="/dashboard"><v-icon>mdi-home</v-icon></v-btn>
        </li>
        <li class="breadcrumb_item_divide">-</li>
        <li>
          <v-btn class="breadcrumb_item" text icon style="font-weight: 400;">{{ $t('menu.settings') }}</v-btn>
        </li>
      </ul>
    </div>
    <v-container grid-list-xl fluid>
      <v-layout wrap>
        <v-flex lg12>
          <v-widget :title="$t('menu.settings')">
            <div slot="widget-content">
              <v-layout v-if="es_iconnecta" wrap justify-space-around align-center class="ma-0">
                <v-tooltip bottom>
                  <v-avatar
                    slot="activator"
                    class="light-blue lighten-1 boton_ajustes"
                    @click="redireccionarTo('/ajustes/agenda')"
                  >
                    <v-icon dark>mdi-calendar-blank</v-icon>
                  </v-avatar>
                  <span>{{ $t('menu.agenda') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-avatar
                    slot="activator"
                    class="deep-orange boton_ajustes"
                    @click="redireccionarTo('/ajustes/servicios')"
                  >
                    <v-icon dark>mdi-video</v-icon>
                  </v-avatar>
                  <span>{{ $t('menu.servicios') }}</span>
                </v-tooltip>
              </v-layout>
              <v-layout v-else wrap justify-space-around align-center class="ma-0">
                <v-tooltip bottom>
                  <v-avatar slot="activator" class="teal boton_ajustes" @click="redireccionarTo('/ajustes/talonario')">
                    <v-icon dark>mdi-ballot</v-icon>
                  </v-avatar>
                  <span>{{ $t('menu.talonario') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-avatar
                    slot="activator"
                    class="light-green boton_ajustes"
                    @click="redireccionarTo('/ajustes/firma')"
                  >
                    <v-icon dark>mdi-gesture</v-icon>
                  </v-avatar>
                  <span>{{ $t('menu.firma') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-avatar
                    slot="activator"
                    class="deep-orange boton_ajustes"
                    @click="redireccionarTo('/ajustes/videoconsultas')"
                  >
                    <v-icon dark>{{ es_dingdoc ? 'mdi-currency-usd' : 'mdi-currency-eur' }} </v-icon>
                  </v-avatar>
                  <span>{{ $t('menu.tarifas') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-avatar
                    slot="activator"
                    class="light-blue lighten-1 boton_ajustes"
                    @click="redireccionarTo('/ajustes/agenda')"
                  >
                    <v-icon dark>mdi-calendar-blank</v-icon>
                  </v-avatar>
                  <span>{{ $t('menu.agenda') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-avatar
                    slot="activator"
                    class="red darken-4 boton_ajustes"
                    @click="redireccionarTo('/ajustes/cupones')"
                  >
                    <v-icon dark>mdi-tag</v-icon>
                  </v-avatar>
                  <span>{{ $t('menu.cupones') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-avatar slot="activator" class="indigo boton_ajustes" @click="redireccionarTo('/ajustes/cupones')">
                    <v-icon dark>mdi-tablet-dashboard</v-icon>
                  </v-avatar>
                  <span>{{ $t('menu.miweb') }}</span>
                </v-tooltip>
              </v-layout>
            </div>
          </v-widget>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import VWidget from '@/components/VWidget.vue';
import { nameEnv } from '../config/config';

export default {
  components: {
    VWidget,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    es_iconnecta: nameEnv === 'iconnecta',
    es_dingdoc: nameEnv === 'dingdoc',
  }),

  methods: {
    redireccionarTo(page) {
      this.$router.push(page);
    },
  },
};
</script>
<style scoped lang="css">
.breadcrumb_item {
  text-transform: none;
  margin: 0px;
  color: #6c6c6c;
}
.breadcrumb_item_divide {
  margin: 0px 10px;
}
.boton_ajustes {
  width: 60px !important;
  height: 60px !important;
}
.boton_ajustes:hover {
  cursor: pointer;
}
</style>
